a {
	&:hover, &.active {
		
	}
	&:focus {
		
	}
	&[href^='tel:'], &[href^='tel:']:hover {
		text-decoration: none;
	}
}

// -----------------------------------
// Titres
// -----------------------------------
h1{
	.oswald;
	font-size: 36px;
	color: @black;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.h1 {
	.oswald;
	font-size: 34px;
	color: @red;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	margin: 20px 0 0px 0;
	padding: 3px 6px;
	background: @white;
	display: inline-block;
	min-width: 0;
	max-width: 500px;
}

h2, .h2 {
	.oswald;
	font-size: 30px;
	color: @black;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	margin: 10px 0 25px 0;
}

.h2_exo {
	.exo-extrabold;
	font-size: 30px;
	line-height: 26px;
	color: @black;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

h3, .h3 {
	.exo-bold;
	font-size: 22px;
	color: @red;
	letter-spacing: 1.5px;
	margin: 35px 0 25px 0;
}

h4, .h4 {
	.exo-bold;
	font-size: 18px;
	color: @black;
	letter-spacing: 1px;
	margin: 35px 0 15px 0;
}

h5, .h5 {
	.exo-medium;
	font-size: 16px;
	text-transform: uppercase;
}



p {
	font-size: 14px;
	line-height: 26px;
	margin: 0 0 20px 0;
}



p.first-paragraph {
	.exo-bold;
	font-size: 16px;
	line-height: 26px;
	margin: 0 0 20px 0;
	color: @red;
}

main {
	ol{
		margin: 30px 0;
	    li {
	    	padding-left: 15px;
	    	margin-bottom: 15px;
	        line-height:24px;
	    }
	}

	ul {
	    @offset-edge-bullet: 15px;
	    @offset-bullet-text: 20px;
	    @bullet-width: 9px;

	    list-style: none;
	    margin: 30px 0;
	    padding-left: @offset-edge-bullet;
	    li {
	        padding-left: @offset-bullet-text+@bullet-width;
	        margin-bottom: 15px;
	        text-indent: 0;
	        text-indent: -@bullet-width;
	        line-height:24px;
	        &:before {
	            content: '';
	            display: inline-block;
	            width: @bullet-width;
	            height: @bullet-width;
	            border-radius: 50%;
	            transform: translateX(-@offset-bullet-text);
	            background-color: @red;
	        }
	    }
	}
}

.date{
	.exo-light;
	background: @red;
	color: @white;
	padding: 5px 10px;
	display: inline-block;

}

blockquote {
	.exo-bold;
	color: @red;
	font-size:16px;
	line-height: 30px;
	border-left: 3px solid #eaeaea;
	padding : 0 0 0 65px;
	
	font-style: italic;
	&:before {
		position: absolute;
        content: url(../img/min/citation.svg);
        display: block;
		width: 24px;
		height: 55px;
		background-color: @white;
		margin: 0 0px 0 -68px;
		padding: 10px 0 0 0;
		float: left
    }
    @media (min-width: @screen-sm-min) {
		float: right;
		width: 30%;
		margin: 0 0 0 65px;
    }
}