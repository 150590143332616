header {
	.nav > li > a {
		padding-left: @navbar-padding-horizontal;
		padding-right: @navbar-padding-horizontal;
	}
}

.nav {
	padding: 15px 0;
	@media (min-width: @screen-md-min) {
		padding: 0;
	}
	.dropdown:hover {
		> .dropdown-menu {
			display: block;
		}
	}
}

.navbar-brand {
	padding: 0;
	height: auto;
}

.navbar-nav {
	@media (max-width: @screen-sm-max) {
		margin: 0;
		// margin-top: 10px;
	}
}

.dropdown-menu {
	@media (max-width: @screen-sm-max) {
		position: relative;
		display: block;
		float: none;
		li {
			a {
			}
		}
	}
}

// -----------------------------------
// nav specific
// -----------------------------------
.nav-secondary {
	text-align: center;
	margin-right: 0;
	@media (min-width: @screen-md-min) {
		text-align: left;
	}
	@media (max-width: @screen-md-max) {
		padding-top: 0;
	}
	a {
		color: @grey-mouse;
		padding-bottom: 6px;
		background: none;
	}
	> li {
		a {
			padding-left: 5px;
			padding-right: 5px;
			&:hover, &:active, &:focus {
				background-color: transparent;
			}
			@media (min-width: @screen-md-min) {
				padding: 22px 11px;
			}
		}
		&:last-child {
			@media (min-width: @screen-md-min) {
				a {
					padding-right: 0;
				}
			}
		}
		&.active {
			a {
				color: @red;
			}
		}
	}
	li.phone {
		a {
			.exo-extrabold;
			font-size: 30px;
			color: @red;
		}
	}
	@media (max-width: @screen-md-max) {
		li a {
			text-align: center;
		    font-weight: 500;
		    font-size: 14px;
		    color: #fff;
		    padding: 15px 0;
		    text-transform: uppercase;
	    	letter-spacing: .5px;
		}
	}
}

.nav-primary {
	@media (max-width: @screen-xs-max) {
		padding-bottom: 0;
	}
	@media (min-width: @screen-md-min) {
		float: right;
	}
	// all
	a {
		text-transform: uppercase;
	}
	// level-specific
	> li {
		position: relative;
		text-align: center;
		> a {
			.exo-medium;
			font-size: 14px;
			color: #fff;
			padding: 15px 0;
			@media (min-width: @screen-md-min) {
				color: @black;
				padding: 17px 25px;
				padding-top: 0;
			}
			&:hover, &:active, &:focus {
				background: none;
			}
		}
		&.active {
			> a {
				color: @red;
			}
		}
		&:last-child {
			@media (min-width: @screen-sm-min) {
				a {
					padding-right: 0;
				}
			}
		}
		.dropdown-menu {
			text-align: center;
			border-radius: 0;
			background: none;
			color: @black;
			padding: 0;
			margin: 0;
			color: #fff;
			border: none;
			box-shadow: none;
			a {
				font-size: 12px;
				color: @grey-smoke;
				padding: 0;
				transition: all .2s ease;
				&:hover, &:active, &:focus {
					background: darken(#e3e3e3, 5%);
					transition: all .2s ease;
				}
			}
			>.active>a {
				background: @red;
				color: #fff;
			}
			@media (min-width: @screen-md-min) {
				border: 1px solid @grey-cloud;
				border-width: 1px 0 1px 0;
				padding: 0;
				left: 50%;
				transform: translateX(-50%);
				background: @grey-cloud;
				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 5.5px 6px 5.5px;
					border-color: transparent transparent #e3e3e3 transparent;
					transform: translateY(0);
					transition: all .2s ease;
					// transition-delay: .4s;
				}
				a {
					color: #434552;
					padding: 11px 20px;
				}
			}
		}
		&:hover {
			.dropdown-menu {
				&:before {
					left: 50%;
					transform: translateX(-50%) translateY(-100%);
					transition: all .2s ease;
					// transition-delay: .4s;
				}
			}
		}
	}
}

// -----------------------------------
// 
// -----------------------------------
@navbar-padding-horizontal: 11px;

.navs-w {
	display: flex;
	flex-direction: row;
	padding: 0;
}

.nav-top {
	// border-top: 4px solid @black-deep;
	position: relative;
	z-index: 99;
	> .container-fluid {
		background: @black;
		@media (min-width: @screen-md-min) {
			background: none;
		}
	}
	@media (min-width: @screen-md-min) {
		background: none;
		border-top: none;
	}
	> .container {
		padding: 0;
	}
	.navbar-header {
		position: relative;
		text-align: center;
		background: @black;
		@media (min-width: @screen-md-min) {
			position: absolute;
		}
		.navbar-toggle {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			margin: 0;
			float: none;
			.icon-bar {
				background: #fff;
			}
		}
	}
	.logo {
		display: inline-block;
		padding: 10px;
		max-width: 80px;
		background: @black;
		transition: all .2s ease;
		@media (min-width: @screen-md-min) {
			border-top: 4px solid @black-deep;
			max-width: none;
			padding: 24px 46px 27px;
			&:hover {
				background: darken(@black,5%);
				border-top: 4px solid darken(@black-deep,5%);
				transition: all .2s ease;
			}
		}
	}
	.navs {
		flex: 1;
		.navbar-collapse {
			border: none;
			box-shadow: none;
		}
		@media (min-width: @screen-md-min) {
			padding-right: 45px;
		}
	} 
	.nav-cta {
		display: none;
		position: relative;
		text-align: center;
		padding-top: 10px;
		@media (min-width: @screen-md-min) {
			display: block;
		}
		> a {
			.exo-extrabold;
			font-size: 25px;
		}
		.contact-banner {
			border-top: 2px solid #ab1233;
			max-width: 100%;
			// background: @red;
			color: #fff;
			padding: 12px 37px;
			position: absolute;
			margin-top: 10px;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 80px;
				background: @red;
				z-index: 1;
			}
			&:hover {
				margin-top: 13px;
				transition: .4s margin ease;
			}
			&:after {
				content: '';
				position: absolute;
				display: block;
				bottom: -18px;
				left: 0;
				width: 100%;
				height: 45px;
				// border-style: solid;
				// border-width: 46px 90px 0 90px;
				// border-color: @red transparent transparent transparent;
				background: url('../img/min/triangle.svg') top center no-repeat;
				background-size: cover;
			}

		}
		span {
			position: relative;
			z-index: 2;
			display: block;
			&:nth-child(1) {
				.exo-medium;
				font-size: 18px;
			}
			&:nth-child(2) {
				.exo-bold;
				font-size: 24px;
				border-bottom: 1px solid #fff;
				margin-bottom: 4px;
			}
			&:nth-child(3) {
				// .exo-bold;
				font-size: 13px;
			}
		}
	}
}

@navbar-margin-bottom: 0;
@navbar-height: 73px;

.untouch {
   background:transparent; 
   position:relative; 
   width:100%;
   height:300px; /* your iframe height */
   top:300px;  /* your iframe height */
   margin-top:-300px;  /* your iframe height */
}