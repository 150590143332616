body.global-home-construct {
	img {
		@media (min-width: @screen-sm-min) {
			margin: 0 0 20px 20px;
		}
		.img-responsive;
	}
	h4 {
		margin-top: 0;
	}
	.objects {
		padding: 15px;
		@media (min-width: @screen-sm-min) {
			padding: 50px 15px;
		}
	}
	.btn {
		margin-bottom: 15px;
	}
}