main {
	> header {
		position: relative;
		display: flex;
		flex-direction: row;
		background: @black-deep;
		a, div {
			flex: 1;
		}
		> .container {
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
			position: relative;
			flex: 0 0 1170px;
			max-width: 100%;
			// padding: 50px 48px;
		}
		h1 {
			margin-top: 150px !important;
		}
		.header-nav {
			display: none;
			padding: 38px 47px;
			min-width: 245px;
			@media (min-width: 1630px) {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
			.exo-medium;
			font-size: 16px;
			text-transform: uppercase;
			color: @grey-cloud;
			text-decoration: none;
			strong {
				display: block;
				font-size: 20.43px;
				.exo-extrabold;
			}
			&.header-nav-left {
				text-align: right;
			}
			&.header-nav-right {
				text-align: left;
			}
		}
	}

	.header-text {
		background: @black-deep;
		a, div {
			flex: 1;
		}
		> .container {
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
			position: relative;
			flex: 0 0 1170px;
			max-width: 100%;
			padding: 50px 48px;
		}
		h1 {
			margin-top: 150px;
		}
	}
}