body.single {
	.wp-post-image {
		float: right;
		max-width: 400px;
		margin: 0 0 30px 30px;
	}
	a[rel=next] {
		float: right;
	}
	a[rel=next], a[rel=prev] {
		color: @grey;
		font-size: 0.9em;
	}
	article {
		padding: 0 10px;
		header {
			height: 70px;
			border-bottom: 2px solid @red;
			padding: 25px 0 25px;
			margin-bottom: 35px;
		}
		p + p {
			color: @grey-mouse;
		}
		main {
			padding-bottom: 50px;
		}
		@media (min-width: @screen-sm-min) {
			padding: 0;
		}
	}
}

body.single-bien {
	.slick-slide {
		display: block;
		img {
			cursor: pointer;
		}
	}
}