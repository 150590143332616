// ie10 specific
// inclus dans main.css, puisque les commentaires conditionnels ne
// fonctionnent pas pour ie10
// la classe .ie10 est ajoutée à <html> en php, cf templates/head.php
html.ie10 {
	@import (multiple) 'ie-all';
	body {
		// tweak-a-lot-here
	}
}

// ie10+
_:-ms-input-placeholder, :root {
	// tweak-a-lot-here
}