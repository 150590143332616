.house-mini {
	position: relative;
	width: 100%;
	height: 9px;
	text-align: center;
	img, svg {
		width: 9px;
		vertical-align: top;
	}
	svg path {
		transition: all .2s ease;
	}
	&:before, &:after {
		content: '';
		position: absolute;
		top: 5px;
		width: 44%;
		display: block;
		border-top: 1px solid #fff;
	}
	&:before {
		left: 0;
	}
	&:after {
		right: 0;
	}
}

.objects {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-left: -15px;
	margin-right: -15px;
	@media (max-width: @screen-xs-max) {
		margin: 0;
		padding-top: 15px;
		display: block;
	}
}

.object-thumbnail-w {
	flex: 1;
	// padding: 0 25px;
	// padding: 0;
	// &:nth-child(even) {
	// 	padding: 0 25px;
	// }
}
.object-thumbnail {
	flex: 1;
	position: relative;
	padding: 0;
	color: #fff;
	font-size: 14px;
	> img {
		min-width: 100%;
	}
	h2 {
		color: #fff;
	}
	figcaption {
		width: 100%;
		opacity: 0;
		position: absolute;
		top: 0;right: 0;bottom: 0;left: 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: fadeout(@black, 10%);
		padding: 15px;
		transition: all .2s ease;
	}
	h2 {
		color: #fff;
	}
	.object-title {
		.oswald;
		text-transform: uppercase;
		font-size: 26px;
		color: #fff;
	}
	.object-text {
		max-width: 100%;
		button {
			text-transform: uppercase;
			color: #fff;
			.exo-bold;
			@media (min-width: @screen-sm-min) {
				color: @black;
			}
		}
	}
	&:hover {
		@media (min-width: @screen-sm-min) {
			figcaption {
				opacity: 1;
				transition: all .2s ease;
			}
			.house-mini {
				margin-bottom: 28px;
				svg path {
					fill: #fff;
					transition: all .2s ease;
				}
			}
			.object-text {
				// margin-bottom: 40px;
			}
		}
	}
}
.object {
	display: flex;
	flex-direction: row;
	background: #f5f5f5;
	margin-bottom: 50px;
	// .object-thumbnail, .object-content {
	// 	width: 50%;
	// }
	@diff: 0.05;
	.object-thumbnail-w {
		flex: 1-@diff;
		display: flex;
	}
	.object-thumbnail {
		margin-bottom: 0 !important;
		position: relative;
		.object-price {
			position: absolute;
			bottom: 0;
			right: 0;
			.oswald;
			font-size: 26px;
			color: #fff;
			background: @red;
			padding: 9px 15px;
		}
	}
	.object-content {

	}
	// flex: 1+@diff;
	// .object-content {
	// 	display: flex;
	// 	flex-direction: column;
	// 	a {
	// 		color: @black;
	// 	}
	// 	p {
	// 		font-size: 14px;
	// 	}
	// }
	.object-text {
		flex: 1;
		padding: 33px 30px;
	}
	h1 {
		.oswald;
		text-transform: uppercase;
		font-size: 24px;
		margin: 0;
		margin-bottom: 10px-2;
	}
	h2 {
		font-size: 14px;
		.exo-medium;
		color: @grey;
		margin: 0;
		margin-bottom: 22px-5;
		// a1a2a8
	}
}

.object-data {
	display: flex;
	flex-direction: row;
	div {
		flex: 1;
		color: #fff;
		background: @black;
		padding: 9px 0;
		text-align: center;
		text-transform: uppercase;
		font-size: 9px;
		span {
			.oswald;
			font-size: 26px;
		}
		&:not(:last-child) {
			border-right: 1px solid @grey;
		}
	}
	.object-details & {
		border-top: 1px solid @grey;
		border-bottom: 1px solid @grey;
		div {
			background: #242424;
		}
	}
}

.link-back {
	background: #eaeaea;
	height: 50px;
	line-height: 50px;
	padding-left: 10px;
	position: relative;
	a {
		color: lighten(#353535, 40%);
	}
}

/*.link-back:before {
	content: "";
	width: 1000px;
	height: 50px;
	background-color: @grey-cloud;
	position: absolute;
	right: 100%;
}*/

.object-details {
	display: flex;
	flex-direction: column;
	.object-desc, .object-specs {
		flex: 1;
	}
	.object-desc {
		h3 {
			margin-top: 8px;
			font-size: 16px;
		}
	}
	.object-desc-text {
		padding: 0 25px;
	}
	.object-specs {
		background: #353535;
		color: #fff;
		display: flex;
		flex-direction: column;
	}
	.object-nav {
		background: #242424;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		max-width: 100%;
		font-size: 16px;
		position: relative;
		.exo;
		i {
			margin-left: 10px;
		}
		&:first-child {
			padding-left: 40px;
		}
		.object-nav-label {
			padding: 20px 0;
		}
		> div {
			display: flex;
			flex: 1;
			div {
				align-self: center;
			}
		}
		@media (min-width: @screen-sm-min) {
			flex-direction: row;
			.object-nav-label {
				padding: 0;
			}
		}
	}
	/* .object-nav:before {
		content: " ";
		width: 100%;
		height: 50px;
		background-color: #242424;
		position: absolute;
		left: 100%;
	} */
	.object-links {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		> div {
			flex: 1;
			padding: 24px 0;
			padding-left: 40px;
			&:first-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				background: #242424;
				color: #5c5c5d;
				text-transform: uppercase;
				.exo-medium;
				font-size: 14px;
				img, svg {
					margin-right: 36px;
				}
			}
			&:last-child {
				.exo-medium;
				font-size: 14px;
				color: #fff;
				text-transform: uppercase;
				background: #2c2c2c;
				a {
					color: @red;
					.exo-bold;
				}
			}
		}
		@media (min-width: @screen-sm-min) {
			flex-direction: row;
		}
	}
	.object-share {
		text-transform: uppercase;
		padding: 37px 0;
		padding-left: 40px;
		color: darken(@grey-mouse, 20%);
		i {
			margin-left: 20px;
			color: #fff;
			font-size: 18px;
			transition: all .2s ease;
			&:hover, &:active, &:focus {
				color: @red;
				transition: all .2s ease;
			}
		}
	}
	.object-address {
		padding: 37px 0 0 40px;
		& div:first-child {
			font-size: 1.1em;
			text-transform: uppercase;
			margin-bottom: 12px;
		}
	}
	@media (min-width: @screen-sm-min) {
		flex-direction: row;
		.object-desc-text {
			padding: 0;
			padding-right: 40px;
		}
	}
}
.object-images {
	background: @grey-smoke;
	> .container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// padding-top: 6px;
		padding-top: 34px-2;
		figure {
			width: 50%;
			margin-bottom: 34px-2;
		}
	}
	h5 {
		margin-top: 0;
		margin-bottom: 24px;
	}

}
.object-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;
	figure {
		padding: 0;
    	width: 100%;
	}
	article, .object-content {
		flex: 1;
	}
	article {
		display: flex;
		.object-thumbnail-w {
			
		}
	}
	&:first {
		margin-top: 30px;
	}
	.object-content {
		padding: 0;
		background: @grey-cloud;
		min-height: 235px;
	}
	.object-text {
		padding-top: 3px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
    	flex-direction: column;
    	padding-bottom: 50px;
	}
	.object-data {
		// bottom: 0;
	 //    position: absolute;
	 //    left: 0;
	 //    right: 0;
	}
	h2 {
		margin-bottom: 5px;
	}
	h2 a {
		font-size: 0.8em;
		color: @black;
	}
	h3 {
		font-size: 1em;
		margin-top: 0;
		margin-bottom: 0;
		color: @grey-mouse;
		font-weight: 100;
	}
	.object-price {
		position: absolute;
		bottom: 0;
		right: 0;
		.oswald;
		font-size: 26px;
		color: #fff;
		background: @red;
		padding: 9px 15px;
		z-index: 100;
	}
	.object-thumbnail {
		margin-bottom: 0;
	}
}
.object-bg {
	display: flex;
}
.object-responsive {
	@media (max-width: @screen-xs-max) {
		margin-bottom: 30px;
		figcaption {
			opacity: 1;
			background: rgba(0,0,0,0);
		}
		button {
			display: none;
		}
		.house-mini {
			display: none;
		}
		h2 {
			font-size: 1em;
			color: @black;
			position: absolute;
			bottom: -65px;
			left: 0;
			display: block;
		}
		.object-text {
			font-size: 1em;
			color: @black;
			position: absolute;
			bottom: -65px;
			left: 0;
			display: block;
		}
	}	
}