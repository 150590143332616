// Captions
.wp-caption {
  &:extend(.thumbnail all);
}

.wp-caption-text {
  &:extend(.thumbnail .caption all);
}

// Gallery shortcode
.gallery-row {
  padding: (@line-height-computed / 2) 0;
}
