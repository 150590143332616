.filters-catalogue {
	background: @grey-cloud;
	> .container > .row {
		display: flex;
		flex-direction: row;
	}
	.filters-title {
		padding: 20px;
		background: @red;
		.exo-extrabold;
		font-size: 18px;
		text-transform: uppercase;
		color: #fff;
		text-align: center;
		i {
			font-size: 16px;
		}
	}
	.checkbox-custom + .checkbox-custom-label:before {
		content: '';
		background: white !important;
	}

	.checkbox-flex {
		display: flex;
		justify-content: space-between;
		align-self: center;
	}
	.checkbox {
		margin: 0;
	}

}