body {
  &:before {
    content: 'responsive';
    display: inline-block;
    position: fixed;
    top: 25px; left: 25px;
    padding: 5px;
    z-index: 999;
    background: yellowgreen;

    display: none;
  }
}

// Bootstrap version
@grid-float-breakpoint: 992px;

// -----------------------------------
// MEDIA QUERIES: Boostrap breakpoints
// -----------------------------------
// mobile
@media (max-width: @screen-md-min) {
}

// < 768px
@media (max-width: @screen-xs-max) {
  body:before {content: '< 768px = xs';}
}

// 768px
@media (min-width: @screen-sm-min) {
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  body:before {content: '> 768px = sm';}
}

// > 992px
@media (min-width: @screen-md-min) {
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  body:before {content: '> 992px = md';}
}

// > 1200px
@media (min-width: @screen-lg-min) {
  body:before {content: '> 1200px = lg';}
}

// -----------------------------------
// MEDIA QUERIES: custom
// -----------------------------------
@media (min-width: @screen-lg-min) and (max-width: 1355px) {
  // 
}