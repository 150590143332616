body.detail-modele {
	.col-1 {
		padding-left: 0;
		padding-right: 60px;
		a {
			background-color: @grey-cloud;
			color: @grey-mouse;
			display: block;
			height: 50px;
			width: 120%;
			line-height: 50px;
		}
		a:before {
			content: "";
			display: block;
			width: 1000px;
			height: 50px;
			background-color: @grey-cloud;
			position: absolute;
			left: -1000px;
		}
	}
	.col-2 {
		background-color: @black;
		min-height: 400px;
		.mini-col {
			height: 50px;
			background-color: blue;
			p {
				color: #fff;
				line-height: 50px;
				padding-left: 20px;
			}
		}
	}
	.wrap {
		overflow: hidden;
	}
	.settings-bg {
		background-color: #242424;
		display: block;
		width: 1000px;
		height: 50px;
		position: absolute;
		left: 0;
		top: 0;
	}
	.col-2:after {
		content: "";
		background-color: @black;
		display: block;
		width: 1000px;
		min-height: 400px;
		position: absolute;
		top: 50px;
		left: 0px;
	}
}