body.home {
	.block > a {
		// display: inline-block;
	}
	.intro {
		h1 {
			.oswald;
			font-size: 30px;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
	}
	.object-thumbnail {
		figcaption {
			opacity: 1;
		}
		.object-text {
			@media (min-width: @screen-sm-min) {
				max-height: 0;
			}
			overflow: hidden;
			transition: all .2s ease;
			p {
				margin-top: 25px;
				margin-bottom: 15px;
				@media (min-width: @screen-sm-min) {
					margin-top: 0px;
				}
			}
		}
		&:hover {
			figcaption {
				background: fadeout(@red, 10%);
			}
			.object-text {
				max-height: 300px;
				transition: all .2s ease;
			}
		}
	}
	.articles {
		padding-top: 68px;
		padding-bottom: 43px;
		margin-left: -15px;
		margin-right: -15px;
		@media (min-width: @screen-sm-min) {
			margin-left: -30px;
			margin-right: -30px;
		}
	}
}