.form-control {
	height: 50px;
	background-color: @grey-cloud;
	border: none;
	border-radius: 0px;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
	padding: 1.3em;
	margin-bottom: 20px;
    box-shadow:inset 0 0 10px @grey-cloud;
}
.form-control:focus {
	background-color: @grey-smoke;
	outline-style:none;
	box-shadow:none;
}
select.form-control {
	background-position: right 50%;
	background-repeat: no-repeat;
	padding: .5em;
	padding-right: 1.5em;
	padding-left: 1.3em;
	color: @black;
}
.form-control-w {
	
	position: relative;
	html:not(.ie) &:after {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
    	.fa;
    	content: @fa-var-sort;
    	// display: block;
    	// width: 40px;
    	// height: 40px;
	}
}

.form-control::-webkit-input-placeholder {
	color: @black;
}
.form-control:-moz-placeholder { /* Firefox 18- */
	color: @black;  
}
.form-control::-moz-placeholder {  /* Firefox 19+ */
	color: @black;  
}
.form-control:-ms-input-placeholder {  
	color: @black;
}

.checkbox {
	label {
		// padding-top: 8px;
		padding-left: 0;
	}
	.checkbox-custom {
		opacity: 0;
		position: absolute;   
	}
	.checkbox-custom, .checkbox-custom-label {
	    display: inline-block;
	    vertical-align: middle;
	    cursor: pointer;
	}
	.checkbox-custom-label {
	    position: relative;
	}
	.checkbox-custom + .checkbox-custom-label:before {
	    content: '';
	    background: @grey-cloud;
	    display: inline-block;
	    vertical-align: middle;
	    width: 36px;
	    height: 36px;
	    padding: 2px;
	    margin-right: 20px;
	    text-align: center;
	}
	.checkbox-custom:checked + .checkbox-custom-label:before {
	    content: "\f00c";
	    font-family: 'FontAwesome';
	    font-size: 1.2em;
	    color: @grey;
	    padding-top: 6px;
	}
	.checkbox-custom:focus + .checkbox-custom-label {
	  outline: none;
	}
}