.articles {
	padding-top: 20px;
	a {
		margin-top: 15px;
		display: block;
		color: #434552;
		text-transform: uppercase;
	}
	p>a {
		color: @red;
		font-weight: bold;
	}
	h2 {
		margin-bottom: 20px;
		font-size: 1.6em;
		line-height: 130%;
	}
	p {
		color: @grey-mouse;
		font-size: 1em;
	}
	.btn {
		display: inline;
		text-align: center !important;
	}
}

.article-thumbnail {
	border: 1px solid #ececed;
	margin-bottom: 50px;
	position: relative;
	padding: 23px 23px 18px 23px;
	text-align: left;
	main {
		a {
			color: @red;
			margin-top: 0;
		}
	}
}

.updated {
	color: #fff;
	font-size: 0.9em;
	background-color: @red;
	padding: 8px 12px 8px 23px;
	position: absolute;
	left: 0;
	top: 45px;
	opacity: 0.9;
}

.wp-post-image {
	width: 100%;
	height: auto;
}
