body.archive {
	.lottissement-search {
		background-color: grey;
		text-align: center;
		background-color: @grey-cloud;
		// margin-bottom: 40px;
		padding-top: 17px;
		padding-bottom: 17px;
		padding-right: 15px;
		padding-left: 15px;
		label { 
			text-transform: uppercase;
			margin: 0;
			padding-top: 5px;
			margin-bottom: 30px;
			@media (min-width: @screen-sm-min) {
				margin-bottom: 0px;
			}
		}
		select.form-control {
			height: 35px;
			background-color: #fff;
			box-shadow:inset 0 0 10px #fff;
			margin-bottom: 0;
			margin-top: -5px;
			@media (min-width: @screen-sm-min) {
				min-width: 350px;
			}
		}
		i {
			margin-left: 10px;
			@media (min-width: @screen-sm-min) {
				margin-right: 10px;
			}
		}
	}
	// .lottissement-search:before {
	// 	content: " ";
	// 	background-color: grey;
	// 	background-color: @grey-cloud;
	// 	height: 70px;
	// 	width: 100%;
	// 	position: absolute;
	// 	right: 0;
	// 	z-index: -1;
	// }
	p {
		font-size: 0.82em;
	}
	.object-thumbnail-w{
		display: flex;
		flex-direction: column;
		// margin-bottom: 30px;
		@media (max-width: @screen-xs-max) {
			padding-left: 0px;
    		padding-right: 0px;
			margin-bottom: 85px;
		}
		
	}
	.object-thumbnail {
		// enable if image & text must be same height
		// flex: 1;
		&:hover {
			background: darken(@red, 10%);
			transition: all .2s ease;
		}
	}
	.lotissements-link-w{
		display: flex;
		flex-direction: column;
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
	}
	.lotissements-link{
		flex: 1;
		background: @red;
		margin-bottom: 0;
		text-decoration: none;
		text-align: center;
		@media (max-width: @screen-xs-max) {
			padding: 50px 0 50px;
		}
	}
	.lottissement-contact {
		font-size: 1em;
	}
	figcaption {
		text-align: center;
	}
	.house-mini svg path {
		margin: auto;
		fill: #fff;
	}
	.house-mini {
			margin-bottom: 28px;
	}
	.form-control-w {
		display: inline;
		@media (max-width: @screen-xs-max) {
			display: block;
		}
		&:after {
			top: 40%;
			@media (max-width: @screen-xs-max) {
				top: 50%;
			}
		}
	}
}