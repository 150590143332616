footer {
	.footer-primary {
		background: @black;
		.footer-primary-line1 {
			padding: 0 0 28px 0;
			border-bottom: 1px solid @grey;
			color: @grey;
			> div:first-child {
				padding-top: 33px;
			}
			> div:nth-child(2) {
				border-left: 1px solid @grey;
			}
			> div:not(:first-child) {
				margin-top: 15px;
				@media (min-width: @screen-sm-min) {
					margin-top: 65px;
				}
			}
			h4 {
				font-size: 16px;
				color: @red;
				text-transform: uppercase;
			}
			i {
				font-size: 28px;
				color: @grey;
				margin-right: 0px;
				@media (min-width: @screen-sm-min) {
					margin-right: 18px;
				}	
			}
			address {
				color: #fff;
			}
			.block {
				display: inline;
				flex-direction: row;
				align-items: center;
				@media (min-width: @screen-sm-min) {
					display:flex;
				}	
			}
			a {
				color: #fff;
			}
		}
		.footer-primary-line2 {
			@media (min-width: @screen-sm-min) {
				display: flex;
				flex-direction: row;
				align-items: center;
			}	
			font-size: 11px;
			color: @grey;
			text-transform: uppercase;
			padding: 16px 0;
			a {
				color: #fff;
			}
			[href*="tel"] {
				.exo-extrabold;
			}
			.footer-tel {
				font-size: 19px;
				@media (min-width: @screen-md-min) {
					font-size: 26px;
				}
				i {
					color: @red;
				}
			}
			.footer-nav {
				visibility: hidden;
				@media (min-width: @screen-md-min) {
					visibility: visible;
					padding: 0;
				}
				@media (min-width: @screen-lg-min) {
					visibility: visible;
				}
				> ul {
					margin: 0;
					li {
						padding: 0;
						&:after {
							content: '•';
							color: @red;
							font-size: 15px;
							line-height: 0;
							transform: translateY(5px);
							margin: 0 15px;
						}
						&:last-child {
							&:after {
								display: none;
							}
						}
					}
				}
			}
			.footer-facebook {
				@media (min-width: @screen-sm-min) {
					text-align: right;
					padding: 0;
				}
				text-align: center;
				i {
					font-size: 20px;
					color: #fff;
					margin-left: 20px;
				}
			}
		}
		img {
			max-width: 128px;
		}
		.img-responsive {
				display: inline;
		}
		.col-sm-3 {
			text-align: center;
			h4 {
				display: none;
				@media (min-width: @screen-sm-min) {
					display: block;
				}
			}
			@media (min-width: @screen-sm-min) {
				text-align: left;
			}
		}
		ul {
			display: inline-block;
			.dropdown-menu, .caret {
				display: none;
			}
			a {
				color: #fff;
			}
		}
	}
	.footer-secondary {
		text-align: center;
		font-size: 12px;
		color: #808080;
		background: @black-deep;
		padding: 18px 0;
	}
}