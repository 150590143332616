.modal-content {
	box-shadow: none;
	border: none;
}

.modal-body {
	padding: 0;
}

.modal-header {
	position: relative;
	.close {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		color: @black-deep;
		opacity: 1;
	}
	.modal-title {
		.oswald;
		font-size: 26px;
		text-transform: uppercase;
		strong {
			color: @red;
		}
	}
}

.modal-slider-w {
	display: flex;
	flex-direction: row;
	max-width: 100%;
	.modal-slider {
		flex: 1;
		width: ~'calc(100% - 61px)';
		// max-width: 100%;
	}
	.modal-slider-nav {
		display: flex;
		flex-direction: column;
		background: @black-deep;
		button, a {
			display: block;
			text-align: center;
			height: 61px;
		}
		button {
			font-size: 32px;
			color: #fff;
			display: inline-block;
			width: 61px;
			height: 61px;
			background: transparent;
			&:first-child {
				border-bottom: 1px solid @grey;
			}
		}
		a {
			font-size: 24px;
			background: @red;
			color: #fff;
			line-height: 61px;
		}
	}
}