body.page-services, body.page-a_vendre {
	.main.container>.row {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0;
		// padding-top: 70px;
		// padding-bottom: 20px;
		// border-bottom: 1px solid @red;
		@media (min-width: @screen-md-min) {
			flex-direction: row;
			padding: 0;
			border: none;
		}
	}
	.object-thumbnail {
		background-image: url(../img/min/block-lotissements.jpg);
		background-size: cover;
		min-height: 220px;
		> a {
			display: block;
		}
		figcaption {
			opacity: 1;
		}
	}
	h4 {
		margin-top: 0;
	}
	.object-thumbnail {
		// display: none;
		@media (min-width: @screen-md-min) {
			display: block;
		}
	}
}

body.page-a_vendre, body.page-services {
	.main {
		> .row {
			padding-bottom: 25px;
			.object-thumbnail {
				figcaption {
					padding: 50px 0;
				}
			}
			> div:not(.object-thumbnail) {
				order: 1;
				padding-top: 25px;
				align-self: center;
				@media (min-width: @screen-md-min) {
					order: initial;
					padding: 60px 50px;
				}
			}
		}
	}
}