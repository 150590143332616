.main {
	padding: 55px 0;
}

.intro {
	background: #eaeaea;
	padding: 68px 0 70px 0;
	h1 {
		margin: 0;
		margin-bottom: 39px;
	}
	p {
		margin: 0 auto;
		font-size: 17px;
	}
	@media (min-width: @screen-sm-min) {
		p {
			width: 50%;
		}
	}
}

.home-blocks {
	background: @black;
	div > a {
		// display: inline-block;
	}
	.block {
		padding: 0;
	}
}

