.btn {
	border-radius: 0;
	outline: 0;
	box-shadow: none;
	display: inline-block;
	white-space: normal;
	.exo;
	font-size: 14px;
	padding: 7px 20px 6px 20px;
	letter-spacing: 0px;
	&.btn-lightblue {
		color: lightblue;
		border-color: lightblue;
		transition: all .2s ease;
		&:hover, &:focus, &:visited, &:active {
			transition: all .2s ease;
		}
	}
}

.btn.btn-red {
	background: @red;
	color: #fff;
}

.btn.btn-gris {
	background: #eaeaea;
	color: @black;
	&:hover {
		color: @white;
	}
}
.btn.btn-outline-white {
	background: none;
	color: @white;
	border: 2px solid @white;
	padding: 12px 25px 11px 25px;
	&:hover {
		color: @red;
		background: @white;
	}
}
.btn.btn-suite-red {
	color: @red;
	padding: 0;
	margin: 0;
	&:hover {
		color: @black;
		background: none;
	}
}

.btn.btn-suite-black {
	color: @black;
	padding: 0;
	margin: 0;
	&:hover {
		color: @red;
		background: none;
	}
}

a.btn {
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
	border-radius: 0;
	&:hover {
		background: @black;
		transition: all .2s ease;
	}
}

.btn-unstyled {
	border: none;
	text-shadow: none;
	background: transparent;
	padding: 0;
	margin: 0;
	outline: 0;
	&:active {
		box-shadow: none;
	}
}