// -----------------------------------
// oswald
// -----------------------------------
@import url(https://fonts.googleapis.com/css?family=Oswald:400);
.oswald {
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
}

// -----------------------------------
// exo
// -----------------------------------
@import url(https://fonts.googleapis.com/css?family=Exo:500,700,800);
.exo {
	font-family: 'Exo', sans-serif;
    letter-spacing: 0.5px;
}

.exo-light {
	.exo;
	font-weight: 200;
}

.exo-medium {
	.exo;
	font-weight: 500;
}

.exo-bold {
	.exo;
	font-weight: 700;
}

.exo-extrabold {
	.exo;
	font-weight: 800;
}