body.page-contact {
	.contact.container {
		margin-bottom: 50px;
	}
	header.row {
		text-align: center;
		border-bottom: 2px @red solid;
		margin-top: 50px;
		margin-bottom: 40px;
		padding-bottom: 35px;
		h2.h2_exo {
			line-height: 130%;
		}
		@media (min-width: @screen-md-min) {
			text-align: left;
			padding-bottom: 10px;
		}
	}
	.call-us-1 {
		color: #fff;
		min-height: 250px;
		background-color: @red;
		h2 {
    		margin: 0;
		}
		p {
			text-transform: uppercase;
			font-size: 1.3em;
			margin-bottom: 0;
			margin-top: 13px;
		}
		a {
			color: #fff;
			font-size: 1.6em;
			@media (min-width: @screen-md-min) {
				font-size: 3.2em;
			}
		}
		figure {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			text-align: center;
			display: -webkit-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	.call-us-2 {
		color: #fff;
		min-height: 320px;
		@media (min-width: @screen-sm-min) {
			min-height: 250px;
		}
		background-color: @black;
		background-image: url(../img/min/block-lotissements.jpg);
		background-size: cover;
		padding-left: 0;
		
		h4 {
			margin-top: 0;
			text-transform: uppercase;
			color: #fff;
		}
		figure {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			display: -webkit-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		input, select {
			height: 40px;
		}
		.form-control[type="tel"] {
			min-width: 200px;
			background-color: #fff;
			padding: 15px;
		}
		p {
			text-transform: uppercase;
		}
		select.form-control {
			background-color: rgba(0,0,0,0);
			box-shadow: none;
			color: @white;
			border: 2px solid @white;
			padding-left: 12px;
		}
		select:focus {
			background-color: rgba(0,0,0,0.3);
		}
		button {
			margin-left: 4px;
			margin-top: 0;
			text-transform: uppercase;
		}
		span {
			margin: 15px;
			display: inline-block;
			height: 40px;
			vertical-align: middle;
		}
		.form-control-w {
			display: inline-block;
			&:after {
				transform: translateY(-120%);
			}	
		}
	}
	.call-us-2:before {
		content: "";
		position: absolute;
		display: block;
		margin: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.8);
	}
	.contact-form {
		margin-bottom: 20px;
		button {
			height: 50px;
			text-transform: uppercase;
			min-width: 130px;
		}
		button {
			height: 50px;
			text-transform: uppercase;
			width: 130px;
		}
	}
	
	textarea.form-control {
		height: 190px;
		resize: none;
	}

	button {
		margin-top: 16px;
	}
}