// -----------------------------------
// custom mixins & utilities
// -----------------------------------
.square(@size) {
	width: @size;
	height: @size;
}

// -----------------------------------
// general styling
// -----------------------------------
address:last-child {
	margin-bottom: 0;
}

.nopadding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.nomargin {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
}

iframe {
	max-width: 100%;
}

body {
	font-size: 15px;
	.exo-medium;
	color: @black;
	// http://stackoverflow.com/questions/12502234/how-to-prevent-webkit-text-rendering-change-during-css-transition
	-webkit-font-smoothing: subpixel-antialiased;
	.flexboxtweener & {
		height: 100%;
	}
	.no-flexboxtweener & {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		.wrap {
			flex: 1 0 auto;
		}
	}
}

.thumbnail {
	a {
		display: inline-block;
	}
	img {
		.img-responsive;
	}
	figcaption {
		.h5;
	}
}

// -----------------------------------
// Animations
// -----------------------------------
@keyframes float {
	0%{transform: translate(0,0)}
	65%{transform: translate(0,15px)}
	100%{transform: translate(0,-0)}
}