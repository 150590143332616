@charset "UTF-8";

// Default Variables

@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "./";
@slick-arrow-color: white;
@slick-dot-color: black;
@slick-dot-color-active: @slick-dot-color;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;

/* Slider */
.slick-loading .slick-list{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}

/* Arrows */

.slick-prev,
.slick-next {
    .btn-unstyled;
    position: absolute;
    display: block;
    cursor: pointer;
    top: 50%;
    z-index: 100;
    transform: translate(0, -50%);
    padding: 0 13px;
    text-align: center;
    outline: none;
    background: @red;
    font-size: 42px;
    color: #fff;
    transition: all .2s ease;
    &:not(.slick-disabled) {
        &:hover, &:focus {
            outline: none;
            background: darken(@red, 10%);
            transition: all .2s ease;
            &:before {
                opacity: @slick-opacity-on-hover;
            }
        }
    }
}

// .slick-prev:before, .slick-next:before {
//     font-family: @slick-font-family;
//     font-size: 20px;
//     line-height: 1;
//     color: @slick-arrow-color;
//     opacity: @slick-opacity-default;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

.slick-prev {
    left: 0;
    padding-left: 10px;
    &[dir="rtl"] {
        left: auto;
        right: 0;
    }
    &:before {
        .fa;
        content: @fa-var-angle-left;
        &[dir="rtl"] {
            // content: @slick-next-character;
        }
    }
}

.slick-next {
    right: 0;
    padding-right: 10px;
    &[dir="rtl"] {
        left: 0;
        right: auto;
    }
    &:before {
        .fa;
        content: @fa-var-angle-right;
        &[dir="rtl"] {
            // content: @slick-prev-character;
        }
    }
}

.slick-disabled {
    background: #737471;
}

/* Dots */
.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: @slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: @slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: @slick-font-family;
                font-size: @slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: @slick-dot-color;
                opacity: @slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: @slick-dot-color-active;
            opacity: @slick-opacity-default;
        }
    }
}


// -----------------------------------
// slider specific
// -----------------------------------
.slick-modele {
    max-width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}
.slick-modele-nav {
    .slick-track {
        width: inherit !important;
    }
    .slick-list {
        overflow: visible;
    }
    .slick-slide {
        position: relative;
        z-index: 2;
        border: 4px solid transparent;
        width: 50px !important;  
    }
    .slick-slide-w {
        height: 42px;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .slick-current {
        z-index: 3;
        border-color: #fff;
        outline: none;
        &:before {
            content: '';
            position: absolute;
            top: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8.5px 7px 8.5px;
            border-color: transparent transparent #ffffff transparent;
        }
    }
}

.slick-home {
    background: @black-deep;

    .slick-slide {
        position: relative;
        display: flex;
        flex-direction: column;
        img {
            max-width: 100%;
            // min-width: 100%;
            margin: 0 auto;
        }
        .slide-desc {
            position: absolute;
            flex: 1;
            width: 100%;
            .container;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            padding: 47px 50px;
            display: flex;
            flex-direction: row;

            // slide elements styles
            .slide-desc-left {
                margin-right: 6px;
                .slide-desc-small {
                    visibility: hidden;
                }
            }
            .slide-desc-small {
                .exo-medium;
                display: inline-block;
                font-size: 14px;
                background: @black;
                padding: 5px 11px;
            }
            .slide-desc-tall {
                .oswald;
                font-size: 40px;
                color: @black;
                background: #fff;
                text-transform: uppercase;
                padding: 0 13px;
                margin: 5px 0;
            }

            // slide data
            .slide-subtitle {
                display: block;
                background: #fff;
                color: @grey;
            }
            .slide-price {
                background: @red;
                color: #fff;
                span {
                    font-size: 20px;
                }
            }
            .slide-link {
                text-transform: uppercase;
                color: #fff;
                .exo-medium;
            }
        }
    }

}

.slick-modal-thumbs {
    border-top: 2px solid @black-deep;
    background: @black-deep;
    img {
        opacity: 0.3;
        transition: all .2s ease;
        &.slick-current, &:hover {
            opacity: 1;
            transition: all .2s ease;
            cursor: pointer;
        }
    }
}